import { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageError from "../components/errors/page-error";
import PaginationBtns from "../components/inputs/pagination-btns";
import LoadingIndicator from "../components/loaders/loading-indicator";
import { useGetAllStoryItemsQuery } from "../store/base-api";
import StoryItemListItem from "../components/list-items/story-item-list-item";
import { CreditSpendTypeToDescription } from "../models";

export default function StoryItems() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const currentPage = Number(params.get("currentPage") || 1);

  const [loading, setLoading] = useState(true);

  const { data, isFetching, isLoading, error } = useGetAllStoryItemsQuery({
    currentPage,
  });

  useEffect(() => {
    setLoading(isFetching || isLoading);
  }, [isLoading, isFetching]);

  return (
    <div className="flex flex-col relative flex-1 w-full overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="sticky z-10 flex flex-wrap px-8 py-2 gap-x-4 gap-y-2 items-center justify-between top-0 bg-white shadow-md">
        <PaginationBtns totalPages={data?.totalPages || 0} />
      </div>

      {error ? (
        <PageError error={error} />
      ) : (
        <div className="flex flex-col p-4 flex-1">
          <div className="flex flex-col flex-1 gap-2">
            {loading || !data ? (
              <div className="flex-1 grid place-items-center">
                <LoadingIndicator />
              </div>
            ) : !data.totalItems ? (
              <EmptyListView />
            ) : (
              data.items.map((storyItem) => (
                <StoryItemListItem
                  key={storyItem.webStoryId + "" + storyItem.creditSpendType}
                  webStoryId={storyItem.webStoryId}
                  storyTitle={storyItem.storyTitle}
                  creditSpendType={storyItem.creditSpendType}
                  created={storyItem.created}
                />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const EmptyListView: FC = () => {
  return (
    <div className="grid flex-1 place-items-center">
      <p>There are currently no story items.</p>
    </div>
  );
};
