import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { SEARCH_REGEX, updateQueryString } from "../../utils";
import { useEffect } from "react";

export default function SearchBar({
  searchText,
  setSearchText,
  placeholder,
}: {
  searchText: string;
  setSearchText(txt: string): void;
  placeholder?: string;
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onClickHandler = () => {
    const searchQuery = updateQueryString("search", searchText, SEARCH_REGEX);
    navigate(`${pathname}${searchQuery}`, { replace: true });
  };

  return (
    <div className="flex items-center border-2 border-primary-500 rounded-xl overflow-hidden focus-within:focused">
      <input
        className="flex py-1 px-4 focus:outline-none min-w-[300px]"
        placeholder={placeholder || "Search"}
        value={searchText}
        onChange={({ target: { value } }) => setSearchText(value)}
      />
      <button
        onClick={onClickHandler}
        className="btn-primary px-2 py-1 rounded-none"
      >
        <SearchIcon height={20} width={20} />
      </button>
    </div>
  );
}
