import { useLocation } from "react-router-dom";
import { BaseError, DiscountCodeReturn } from "../models";
import { getDiscountCodeTypeString } from "../utils";
import { ChangeEvent, useEffect, useState } from "react";
import Toggle from "../components/inputs/toggle";
import dayjs from "dayjs";
import { useUpdateDiscountCodeMutation } from "../store/base-api";
import FormError from "../components/errors/form-error";

export default function EditDiscountCode() {
  const { state } = useLocation() as { state: DiscountCodeReturn };
  const {
    id,
    code,
    isActive,
    expirationDate,
    discountCodeType,
    discountPercentage,
  } = state;
  const [updateCode] = useUpdateDiscountCodeMutation();

  const [active, setActive] = useState(isActive);
  const [currentExpiry, setCurrentExpiry] = useState(
    dayjs(expirationDate).format("YYYY-MM-DD"),
  );
  const [saving, setSaving] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [error, setError] = useState<BaseError>();

  useEffect(() => {
    setInvalidDate(currentExpiry === "Invalid Date");
  }, [currentExpiry]);

  useEffect(() => {
    setSaveDisabled(saving || invalidDate);
  }, [saving, invalidDate]);

  const updateExpiryDate = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setCurrentExpiry(dayjs(value).format("YYYY-MM-DD"));
  };

  const saveChanges = async () => {
    if (saving || invalidDate) return;
    setSaving(true);

    try {
      await updateCode({ id, isActive: active }).unwrap();
      await updateCode({
        id,
        expirationDate: dayjs(currentExpiry).toISOString(),
      }).unwrap();
    } catch (e: any) {
      setError(e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="flex flex-1 w-full p-4 overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="flex p-8 gap-5 flex-col flex-1 h-max min-h-[calc(100vh-128px)] border-2 border-neutral-50 bg-white rounded-2xl shadow-xl">
        <div className="grid grid-cols-[max-content,_1fr] items-center gap-2 gap-x-8">
          <p className="text-xl font-semibold col-span-full">{code}</p>

          <p>Type:</p>
          <p>{getDiscountCodeTypeString(discountCodeType)}</p>

          <p>Precentage:</p>
          <p>%{discountPercentage}</p>

          <p>Valid until:</p>
          <div className="flex items-center gap-4">
            <input
              type="date"
              className="border-2 py-1 px-2 border-secondary-900 rounded-xl"
              min={dayjs().format("YYYY-MM-DD")}
              value={currentExpiry}
              onChange={updateExpiryDate}
            />
            {invalidDate ? (
              <p className="text-error-500">Invalid date</p>
            ) : null}
          </div>

          <p>Is Active:</p>
          <Toggle isToggled={active} setIsToggled={setActive} />
        </div>

        <FormError error={error} />

        <button
          onClick={saveChanges}
          className="btn-primary"
          disabled={saveDisabled}
        >
          Sav{saving ? "ing" : "e"} Changes
        </button>
      </div>
    </div>
  );
}
