import { FC, Fragment, useEffect, useState } from "react";
import {
  useDeleteStoryMutation,
  useGetOneStoryQuery,
  useRegenerateStoryImageMutation,
} from "../store/base-api";
import { Link, useParams } from "react-router-dom";
import LoadingIndicator from "../components/loaders/loading-indicator";
import PageError from "../components/errors/page-error";
import {
  env,
  formatDate,
  getGenderString,
  getObjFromObj,
  getPodcastLifecycleString,
  getVoiceTypeString,
  splitOnUppercase,
} from "../utils";
import { BaseWebStory, StorySegment } from "../models";
import { ReactComponent as RegenerateIcon } from "../assets/icons/rotate-cw.svg";
import { ReactComponent as AlertTriangleIcon } from "../assets/icons/alert-triangle.svg";

const STORY_INFO: BaseWebStory = {
  id: "",
  created: "",
  topLevelCategory: "",
  slug: "",
  shortId: "",
  storyType: 0,
  storyTitle: "",
  storyPrompt: "",
  summary: "",
  storyLikes: 0,
  isDone: false,
  isPublic: false,
  isPluginGenerated: false,
  coverImage: "",
};

export const getStoryTypeName = (storyType: number) => {
  switch (storyType) {
    case 1:
      return "story";
    case 2:
      return "video";
    case 3:
      return "trend";
    default:
      return "video";
  }
};

const POLLING_INTERVAL = 5000;

export default function ViewStory() {
  const { id } = useParams();
  const [pollingInterval, setPollingInterval] = useState(0);
  const { data, isLoading, error } = useGetOneStoryQuery(id!, {
    skip: !id,
    pollingInterval,
  });
  const [regenerateStoryImage] = useRegenerateStoryImageMutation();
  const [deleteStory] = useDeleteStoryMutation();

  const [deletingStory, setDeletingStory] = useState(false);
  const [storyInfo, setStoryInfo] = useState(STORY_INFO);

  useEffect(() => {
    if (!data) return;
    setStoryInfo(getObjFromObj(data, STORY_INFO));
    setPollingInterval(
      data.storySegments.some(({ imageRegenerating }) => imageRegenerating)
        ? POLLING_INTERVAL
        : 0,
    );
  }, [data]);

  const getObjectValue = (key: string, value: any) => {
    switch (key) {
      case "isDone":
      case "isPublic":
      case "isPluginGenerated":
        return value ? "true" : "false";
      case "coverImage":
        return (
          <img
            src={env("imageKit") + value}
            className="min-w-[300px] min-h-[300px] w-[300px] h-[300px] shadow-xl"
          />
        );
      case "created":
        return formatDate(value);
      case "storyLikes":
        return value || "0";
      case "appleLink":
      case "spotifyLink":
        return (
          <a href={value} rel="noreferrer" target="_blank">
            {value}
          </a>
        );
      case "podcastLifecycle":
        return getPodcastLifecycleString(value);
      case "gender":
        return getGenderString(value);
      case "voiceType":
        return getVoiceTypeString(value);
      default:
        return value;
    }
  };

  const deleteStoryHandler = async () => {
    if (deletingStory || !storyInfo.isPublic) return;

    if (window.confirm("Are you sure you want to delete this story?")) {
      setDeletingStory(true);
      try {
        await deleteStory(id!).unwrap();
      } catch (e: any) {
        window.alert(e);
      } finally {
        setDeletingStory(false);
      }
    }
  };

  const regenerateImage = async (imageIndex: number) => {
    try {
      await regenerateStoryImage({ webStoryId: id!, imageIndex }).unwrap();
      setPollingInterval(POLLING_INTERVAL);
    } catch (e: any) {
      setPollingInterval(0);
      throw e;
    }
  };

  if (error) return <PageError error={error} />;
  console.log(data);

  return (
    <div className="flex flex-1 w-full p-4 overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="flex p-8 flex-col flex-1 h-max min-h-[calc(100vh-128px)] border-2 border-neutral-50 bg-white rounded-2xl shadow-xl">
        {isLoading || !data ? (
          <div className="flex-1 grid place-items-center">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex-1 flex flex-col gap-8 max-w-5xl">
            <div>
              <h3 className="mb-4 text-2xl font-semibold">Story Info</h3>
              <div className="grid auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                <Fragment key="story-url">
                  <p className="font-semibold text-lg capitalize">Story URL</p>
                  <div>
                    <a
                      href={`https://www.story.com/${getStoryTypeName(data.storyType)}/${data.topLevelCategory}/${data.slug}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      {`https://www.story.com/${getStoryTypeName(data.storyType)}/${data.topLevelCategory}/${data.slug}`}
                    </a>
                  </div>
                </Fragment>
                {Object.entries(storyInfo).map(([key, value]) => (
                  <Fragment key={`story-info-${key}`}>
                    <p className="font-semibold text-lg capitalize">
                      {splitOnUppercase(key)}
                    </p>
                    <div>{getObjectValue(key, value) || "N/A"}</div>
                  </Fragment>
                ))}
              </div>
            </div>
            <div>
              <h3 className="text-2xl font-semibold">Created By</h3>
              <div className="my-4 grid auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                {Object.entries(data.user).map(([key, value]) => (
                  <Fragment key={`user-info-${key}`}>
                    <p className="font-semibold text-lg capitalize">
                      {splitOnUppercase(key)}
                    </p>
                    <div>{getObjectValue(key, value) || "N/A"}</div>
                  </Fragment>
                ))}
              </div>
              <Link to={`/users/${data.user.id}`} className="btn-primary">
                View User
              </Link>
            </div>
            <div>
              <h3 className="mb-4 text-2xl font-semibold">Amazon Request</h3>
              {data.amazonBook ? (
                <Link className="btn-primary" to={`/amazon-request/${id}`}>
                  View Amazon request
                </Link>
              ) : (
                <p>This story has not been published to Amazon</p>
              )}
            </div>
            <div>
              <h3 className="mb-4 text-2xl font-semibold">Book Orders</h3>
              {data.bookOrders.length ? (
                <div className="flex flex-col gap-2">
                  {data.bookOrders.map((bo) => (
                    <Link
                      key={bo.id}
                      className="btn-primary"
                      to={`/rpi-request/${id}`}
                    >
                      View book order
                    </Link>
                  ))}
                </div>
              ) : (
                <p>This story has no book orders</p>
              )}
            </div>
            <div>
              <h3 className="mb-4 text-2xl font-semibold">Story Podcast</h3>
              {data.storyPodcast ? (
                <div className="grid auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                  {Object.entries(data.storyPodcast).map(([key, value]) => (
                    <Fragment key={`user-info-${key}`}>
                      <p className="font-semibold text-lg capitalize">
                        {splitOnUppercase(key)}
                      </p>
                      <div>{getObjectValue(key, value) || "N/A"}</div>
                    </Fragment>
                  ))}
                </div>
              ) : (
                <p>This story no podcast</p>
              )}
            </div>
            <div>
              <h3 className="mb-4 text-2xl font-semibold">Story Segments</h3>
              <div className="flex flex-col gap-4">
                {data.storySegments.map((s, i) => (
                  <Segment
                    key={`segment-${i + 1}`}
                    segment={s}
                    regenerateImage={regenerateImage}
                  />
                ))}
              </div>
            </div>

            <button
              disabled={deletingStory || !storyInfo.isPublic}
              onClick={deleteStoryHandler}
              className="btn-error"
            >
              <AlertTriangleIcon />
              <span>Delete Story</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

const Segment: FC<{
  segment: StorySegment;
  regenerateImage(imageIndex: number): Promise<void>;
}> = ({ segment, regenerateImage }) => {
  const { imageKey, textContent, index } = segment;
  const [imageRegenerating, setImageRegenerating] = useState(
    segment.imageRegenerating,
  );

  const regenerateImageHandler = async () => {
    if (imageRegenerating) return;
    if (!window.confirm("Are you sure you want to regenerate this image?"))
      return;
    setImageRegenerating(true);
    try {
      await regenerateImage(index);
    } catch (e: any) {
      setImageRegenerating(false);
      window.alert(e?.data?.title);
    }
  };

  return (
    <div className="flex gap-4">
      <div
        className="relative min-w-[300px] min-h-[300px] w-[300px] h-[300px] shadow-xl"
        onClick={regenerateImageHandler}
      >
        <img
          className="absolute inset-0"
          style={imageRegenerating ? { opacity: 0.3 } : undefined}
          src={env("imageKit") + imageKey}
        />

        {imageRegenerating ? (
          <div className="absolute inset-0 grid place-items-center">
            <div className="flex">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
          <button className="btn-primary absolute px-2 py-1 rounded-none rounded-bl-2xl top-0 right-0">
            <RegenerateIcon height={20} width={20} />
          </button>
        )}
      </div>
      <p>{textContent}</p>
    </div>
  );
};
