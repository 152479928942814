import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageError from "../components/errors/page-error";
import LoadingIndicator from "../components/loaders/loading-indicator";
import { useGetSingleStoryItemQuery } from "../store/base-api";
import { CreditSpendTypeToDescription } from "../models";

export default function SingleStoryItem() {
  const { id = "", storyItemType = "" } = useParams<{
    id: string;
    storyItemType: string;
  }>();
  const [loading, setLoading] = useState(true);
  const { data, isFetching, isLoading, error } = useGetSingleStoryItemQuery(
    {
      id: id,
      storyItemType: storyItemType,
    },
    {
      skip: !id || !storyItemType,
    },
  );

  useEffect(() => {
    setLoading(isFetching || isLoading);
  }, [isLoading, isFetching]);

  return (
    <div className="flex flex-col p-4 w-full space-y-4">
      {error ? (
        <PageError error={error} />
      ) : loading || !data ? (
        <div className="flex-1 grid place-items-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <h1 className="text-2xl font-bold">{data.storyTitle}</h1>
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="divide-y divide-gray-200">
              <tr>
                <td className="px-4 py-2 font-medium">Story Item Type:</td>
                <td className="px-4 py-2">
                  {CreditSpendTypeToDescription[data.creditSpendType]}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-medium">Created On:</td>
                <td className="px-4 py-2">{data.created}</td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-medium">Slug:</td>
                <td className="px-4 py-2">{data.slug}</td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-medium">PDF:</td>
                <td className="px-4 py-2">
                  {data.pdfUrl ? (
                    <a
                      className="text-blue-500 hover:underline"
                      href={data.pdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download PDF
                    </a>
                  ) : (
                    <span className="text-gray-500">PDF not finisihed</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
