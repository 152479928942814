import { FC, useEffect, useState } from "react";
import { useGetAllDiscountCodesQuery } from "../store/base-api";
import LoadingIndicator from "../components/loaders/loading-indicator";
import DiscountCodeListItem from "../components/list-items/discount-code-list-item";
import { Link } from "react-router-dom";

export default function DiscountCodes() {
  const { data, isLoading } = useGetAllDiscountCodesQuery();

  const [codes, setCodes] = useState(data);

  useEffect(() => {
    setCodes(data);
  }, [data]);

  return (
    <div className="flex flex-col relative flex-1 w-full overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="sticky z-10 flex flex-wrap px-8 py-2 gap-x-4 gap-y-2 items-center justify-between top-0 bg-white shadow-md">
        <h3 className="text-2xl font-semibold">Current Codes</h3>
        <Link to="/discount-codes/create-new" className="btn-primary">
          Create New Code
        </Link>
      </div>

      <div className="flex flex-col p-4 flex-1">
        <div className="flex flex-col flex-1 gap-2">
          {isLoading || !codes ? (
            <div className="flex-1 grid place-items-center">
              <LoadingIndicator />
            </div>
          ) : codes?.length ? (
            codes.map((dc) => <DiscountCodeListItem key={dc.id} {...dc} />)
          ) : (
            <EmptyListView />
          )}
        </div>
      </div>
    </div>
  );
}

const EmptyListView: FC = () => {
  return (
    <div className="grid flex-1 place-items-center">
      <p>There are currently no discount codes set up.</p>
    </div>
  );
};
