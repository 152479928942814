import process from "process";
import { envs } from "./env";
import { Buffer } from "buffer";
import dayjs, { Dayjs } from "dayjs";
import {
  BaseError,
  DiscountCodeType,
  Gender,
  PodcastLifecycle,
  StoryReportReason,
  VoiceType,
} from "./models";

const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

export function buildQueryString(query: object) {
  if (typeof query !== "object") return "";
  const keys = Object.keys(query);
  if (keys.length === 0) return "";
  return (
    "?" +
    keys
      .filter((k) => query[k] != null)
      .map((k) => `${k}=${query[k]}`)
      .join("&")
  );
}

// https://stackoverflow.com/a/42833475
const atob = (input: string) => {
  const str = input.replace(/=+$/, "");
  let output = "";
  if (str.length % 4 == 1) {
    throw new Error(
      "'atob' failed: The string to be decoded is not correctly encoded.",
    );
  }
  for (
    let bc = 0, bs = 0, buffer: any, i = 0;
    // eslint-disable-next-line no-cond-assign
    (buffer = str.charAt(i++));
    ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    buffer = chars.indexOf(buffer);
  }
  return output;
};

export const getObjFromObj = <T extends object>(obj: object, returnObj: T): T =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => Object.keys(returnObj).includes(key)),
  ) as T;

export const decodeToken = (token: string) => {
  if (!token) return null;
  const decoded = atob(token.split(".")[1]);
  return JSON.parse(decoded);
};

export const blobToBase64 = (blob: Blob) => {
  if (!blob) return;
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const base64toBlob = (b64Data: string, contentType = "") => {
  if (!b64Data.length) return null;
  const [header, data] = b64Data.split(",");
  const buffer = Buffer.from(data, "base64");
  if (!contentType)
    contentType = (/(?:[^:]*:)([^;]*)(?:;)/.exec(header) || [""]).pop()!;
  const blob = new Blob([buffer], { type: contentType });
  return blob;
};

export const splitOnUppercase = (text: string) =>
  text
    .split(/(?=[A-Z][a-z])|(?<=[a-z])(?=[A-Z])|(?<=[a-zA-Z])(?=\d+)/)
    .join(" ");

export const getKeyOfObjFromValue = (obj: object, value: any) =>
  Object.entries(obj)
    .filter(([k]) => isNaN(Number(k)))
    .find(([_, v]) => v === value)?.[0] || "";

export interface Env {
  production: boolean;
  app_url: string;
  api_url: string;
  imageKit: string;
}

let currentEnv: any;
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

try {
  if (isDev) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    currentEnv = require("./env-name.ts").default;
  }
  // eslint-disable-next-line no-empty
} catch (_) {}

export const envName: keyof typeof envs = isDev
  ? currentEnv || "staging"
  : "prod";

export function env<K extends keyof Env>(key: K): Env[K] {
  return (envs as any)[envName][key];
}

export const toMinsSecs = (secs: number): string => {
  const mins = Math.floor(secs / 60)
    .toFixed(0)
    .padStart(2, "0");
  const sec = Math.floor(secs % 60)
    .toFixed(0)
    .padStart(2, "0");
  return `${mins}:${sec}`;
};

export const formatDate = (date: string | Dayjs, formatString?: string) =>
  !date
    ? ""
    : (typeof date === "string" ? dayjs(date) : date)
        .utc()
        .local()
        .format(formatString || "DD MMM, YYYY");

export const getQueryParamsObj = (searchParams: URLSearchParams) => {
  const params: { [key: string]: string } = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

export const getGenderString = (g: Gender) => {
  switch (g) {
    case Gender.Male:
      return "Male";
    case Gender.Female:
      return "Female";
    default:
      return "Prefer not to say";
  }
};

export const getVoiceTypeString = (vt: VoiceType) =>
  `Generic ${vt === VoiceType.GenericFemale ? "fe" : ""}male`;

export const getPodcastLifecycleString = (pl: PodcastLifecycle) => {
  switch (pl) {
    case PodcastLifecycle.Requested:
      return "Requested";
    case PodcastLifecycle.Processing:
      return "Processing";
    case PodcastLifecycle.Error:
      return "Error";
    case PodcastLifecycle.Publishing:
      return "Publishing";
  }
};

export const getDiscountCodeTypeString = (type: DiscountCodeType) => {
  switch (type) {
    case DiscountCodeType.None:
      return "None";
    case DiscountCodeType.BookOrder:
      return "Book order";
    default:
      return "Unknown";
  }
};

export const getReportReasonString = (reason: StoryReportReason) => {
  switch (reason) {
    case StoryReportReason.InappropriateText:
      return "Contains inappropriate language";
    case StoryReportReason.NotLoading:
      return "Story will not finish generating the content";
    case StoryReportReason.HateSpeech:
      return "Contains hateful themes towards real people.";
    case StoryReportReason.Other:
      return "Other reason";
    case StoryReportReason.InapropriateImages:
      return "Contains inappropriate images";
  }
};

export const isGuid = (id: string) =>
  /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
    id,
  );

const DEFAULT_ERROR_MESSAGE = "An unknown error occurred";
export const normalizeError = (error: BaseError) => {
  const e = error as any;
  let message: string;

  if (typeof e?.data?.errors === "object") {
    message = Object.entries(e.data.errors)
      .map(([_, value]) => `${value}`)
      .join("\n");
  } else {
    message =
      typeof e?.data === "string"
        ? e.data
        : e?.data?.message ||
          e?.data?.title ||
          e?.message ||
          e?.error ||
          DEFAULT_ERROR_MESSAGE;
    if (!/[^\w\s]$/.test(message)) message += ".";
  }

  return {
    status: e?.originalStatus || e?.status || e?.code || undefined,
    message,
  };
};

export const SEARCH_REGEX = /search=([^&]*)/;
export const CURRENT_PAGE_REGEX = /currentPage=([^&]+)/;

export const updateQueryString = (
  key: string,
  value: string | number | boolean,
  regex: RegExp,
) => {
  if (!(key && value && regex)) return "";
  const search = window.location.search;
  if (!search) return `?${key}=${value}`;
  const match = search.match(regex);
  if (match) return search.replace(match[0], `${key}=${value}`);
  return `${search}&${key}=${value}`;
};
