import { Link } from "react-router-dom";
import { StoryUser } from "../../models";
import { formatDate } from "../../utils";

export default function UserListItem({ id, name, email, created }: StoryUser) {
  return (
    <Link
      to={`/users/${id}`}
      className="flex flex-col gap-2 p-4 rounded-2xl border-2 border-neutral-50 bg-white shadow-md cursor-pointer hover:bg-neutral-50"
    >
      <p className="text-2xl font-semibold">{name || "N/A"}</p>
      <p>{email}</p>
      <p>Member since {formatDate(created)}</p>
    </Link>
  );
}
