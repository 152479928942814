import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteStoryMutation,
  useGetOneReportedStoryQuery,
  useResolveReportedStoryMutation,
} from "../store/base-api";
import LoadingIndicator from "../components/loaders/loading-indicator";
import {
  env,
  formatDate,
  getReportReasonString,
  splitOnUppercase,
} from "../utils";
import { Fragment, useEffect, useState } from "react";
import { BaseError } from "../models";
import FormError from "../components/errors/form-error";
import PageError from "../components/errors/page-error";

export default function ReportedStory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data,
    isLoading,
    error: fetchError,
  } = useGetOneReportedStoryQuery(id!, { skip: !id });
  const [resolveReports] = useResolveReportedStoryMutation();
  const [deleteStory] = useDeleteStoryMutation();

  const [resolvingReports, setResolvingReports] = useState(false);
  const [deletingStory, setDeletingStory] = useState(false);
  const [error, setError] = useState<BaseError>();
  const [story, setStory] = useState(data);

  useEffect(() => {
    if (!data) return;
    setStory(data);
  }, [data]);

  const getObjectValue = (key: string, value: any) => {
    switch (key) {
      case "isDone":
      case "isPublic":
      case "isPluginGenerated":
        return value ? "true" : "false";
      case "coverImage":
        return (
          <img
            src={env("imageKit") + value}
            className="min-w-[300px] min-h-[300px] w-[300px] h-[300px] shadow-xl"
          />
        );
      case "created":
        return formatDate(value);
      case "storyLikes":
        return value || "0";
      default:
        return value;
    }
  };

  const resolveReportsHandler = async () => {
    if (resolvingReports || !id || !story?.storyReports.length) return;
    setResolvingReports(true);
    try {
      await resolveReports(id).unwrap();
      setStory({ ...story, storyReports: [] });
    } catch (e: any) {
      setError(e);
    } finally {
      setResolvingReports(false);
    }
  };

  const deleteStoryHandler = async () => {
    if (
      deletingStory ||
      !id ||
      !story?.isPublic ||
      !window.confirm("Are you sure you want to delete this story?")
    )
      return;

    setDeletingStory(true);
    try {
      await deleteStory(id).unwrap();
      navigate("/reports", { replace: true });
    } catch (e: any) {
      setError(e);
    } finally {
      setDeletingStory(false);
    }
  };

  if (fetchError) return <PageError error={fetchError} />;

  return (
    <div className="flex flex-1 w-full p-4 overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="flex p-8 flex-col flex-1 h-max min-h-[calc(100vh-128px)] border-2 border-neutral-50 bg-white rounded-2xl shadow-xl">
        {isLoading || !story ? (
          <div className="flex-1 grid place-items-center">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex-1 flex flex-col gap-8 max-w-5xl">
            <div className="flex flex-col gap-4">
              <h3 className="text-2xl font-semibold">Story Info</h3>
              <div className="grid auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                {Object.entries(story)
                  .filter(([key]) => !["storyReports", "user"].includes(key))
                  .map(([key, value]) => (
                    <Fragment key={`story-info-${key}`}>
                      <p className="font-semibold text-lg capitalize">
                        {splitOnUppercase(key)}
                      </p>
                      <div>{getObjectValue(key, value) || "N/A"}</div>
                    </Fragment>
                  ))}
              </div>
              <Link to={`/stories/${id}`} className="btn-primary">
                View Full Story
              </Link>
            </div>

            <div className="flex flex-col gap-4">
              <h3 className="text-2xl font-semibold">Created By</h3>
              <div className="grid auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                {Object.entries(story.user).map(([key, value]) => (
                  <Fragment key={`story-info-${key}`}>
                    <p className="font-semibold text-lg capitalize">
                      {splitOnUppercase(key)}
                    </p>
                    <div>{getObjectValue(key, value) || "N/A"}</div>
                  </Fragment>
                ))}
              </div>
              <Link to={`/users/${story.user.id}`} className="btn-primary">
                View User
              </Link>
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between gap-8">
                <h3 className="text-2xl font-semibold">
                  Story Reports ({story.storyReports?.length || 0})
                </h3>
                <div className="flex items-center gap-4">
                  <button
                    disabled={
                      resolvingReports ||
                      !story.storyReports?.length ||
                      deletingStory
                    }
                    onClick={resolveReportsHandler}
                    className="btn-primary"
                  >
                    Resolv{resolvingReports ? "ing" : "e"} Reports
                  </button>
                  <button
                    disabled={deletingStory || !story?.isPublic}
                    onClick={deleteStoryHandler}
                    className="btn-error"
                  >
                    Delet{deletingStory ? "ing" : "e"} Story
                  </button>
                </div>
              </div>

              <FormError error={error} />

              <div className="flex flex-col flex-1 gap-2">
                {story.storyReports?.length ? (
                  story.storyReports.map((report) => (
                    <div
                      key={`story-report-${report.user.id}`}
                      className="flex flex-col gap-2 p-4 border-2 border-neutral-50 rounded-2xl bg-white shadow-md"
                    >
                      <div className="grid w-full auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                        <h4 className="text-xl font-semibold col-span-full pb-0.5 mb-1 border-b-2 border-neutral-200">
                          Report
                        </h4>
                        <p className="font-semibold capitalize">
                          report reason
                        </p>
                        <p>{getReportReasonString(report.storyReportReason)}</p>
                        <p className="font-semibold capitalize">other reason</p>
                        <p>{report.otherReason}</p>
                        <h4 className="text-xl font-semibold col-span-full pb-0.5 mb-1 border-b-2 border-neutral-200">
                          Reported by
                        </h4>
                        {Object.entries(report.user).map(([key, value]) => (
                          <Fragment key={`user-info-${key}`}>
                            <p className="font-semibold capitalize">
                              {splitOnUppercase(key)}
                            </p>
                            <div>{getObjectValue(key, value) || "N/A"}</div>
                          </Fragment>
                        ))}
                      </div>
                      <Link
                        to={`/users/${report.user.id}`}
                        className="mt-2 btn-primary"
                      >
                        View User
                      </Link>
                    </div>
                  ))
                ) : (
                  <p>There are no reports for this story.</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
