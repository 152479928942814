import { useEffect, useState } from "react";
import PageError from "../components/errors/page-error";
import {
  useGetReportedCommentsQuery,
  useGetReportedStoriesQuery,
} from "../store/base-api";
import { useLocation } from "react-router-dom";
import PaginationBtns from "../components/inputs/pagination-btns";
import LoadingIndicator from "../components/loaders/loading-indicator";
import ReportedStoryListItem from "../components/list-items/reported-story-list-item";
import { REPORTS_TABS } from "../models";
import TabbedMenu from "../components/inputs/tabbed-menu";
import ReportedCommentListItem from "../components/list-items/reported-comment-list-item";

export default function Reports() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const currentPage = Number(params.get("currentPage") || 1);
  const { data, error, isFetching, isLoading } = useGetReportedStoriesQuery({
    currentPage,
  });
  const [loading, setLoading] = useState(true);

  const { data: commentsData } = useGetReportedCommentsQuery({ currentPage });
  const current = params.get("currentTab") || REPORTS_TABS[0].key;

  const currentTab = Number(
    REPORTS_TABS.find(({ key }) => key === current)?.value || 0,
  );

  useEffect(() => {
    setLoading(isFetching || isLoading);
  }, [isLoading, isFetching]);

  return (
    <div className="flex flex-col relative flex-1 w-full overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="sticky z-10 flex flex-wrap px-8 py-2 gap-x-4 gap-y-2 items-center justify-between top-0 bg-white shadow-md">
        <TabbedMenu tabs={REPORTS_TABS} />

        <PaginationBtns totalPages={data?.totalPages || 0} />
      </div>

      {error ? (
        <PageError error={error} />
      ) : (
        <div className="flex flex-col p-4 flex-1">
          <div className="flex flex-col flex-1 gap-2">
            {loading || !data ? (
              <div className="flex-1 grid place-items-center">
                <LoadingIndicator />
              </div>
            ) : currentTab == REPORTS_TABS[0].value ? (
              !data.totalItems ? (
                <div className="grid flex-1 place-items-center">
                  <p>There are currently no reported stories.</p>
                </div>
              ) : (
                data.items.map((story) => (
                  <ReportedStoryListItem key={story.id} {...story} />
                ))
              )
            ) : (
              commentsData?.items.map((comment) => (
                <ReportedCommentListItem
                  key={comment.comment.id}
                  {...comment}
                />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}
