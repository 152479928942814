import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  AmazonPublishLifecycle,
  AmazonRequestInterface,
  BookOrder,
  DiscountCode,
  DiscountCodeReturn,
  PaginatedList,
  ReportedComment,
  ReportedWebStory,
  RpiTab,
  StoryItem,
  StoryUser,
  WebStory,
} from "../models";
import { buildQueryString, env, isGuid } from "../utils";

const DEFAULT_PAGE_SIZE = 50;

interface GetAllParams {
  currentPage: number;
  pageSize?: number;
}
interface GetAllParamsWithSearch extends GetAllParams {
  pattern: string;
}

export interface AmazonGetAllParams extends GetAllParams {
  amazonPublishLifecycle: AmazonPublishLifecycle;
}

export interface RPIGetAllParams extends GetAllParams {
  currentTab: RpiTab;
}

export interface LoginParams {
  email: string;
  password: string;
}

const prepareHeaders = (headers: Headers) => {
  const token = localStorage.getItem("authToken");
  if (token) headers.set("authorization", `Admin ${token}`);
  return headers;
};

const tagTypes = [
  "stories",
  "story",
  "users",
  "storyUser",
  "amazonReqList",
  "amazonReq",
  "bookOrders",
  "bookOrder",
  "discountCodes",
  "reportedStories",
  "reportedStory",
];

const buildGetAllQueryString = <T extends GetAllParams>(params: T) =>
  buildQueryString({
    ...params,
    pageSize: params?.pageSize || DEFAULT_PAGE_SIZE,
  });

const buildGetAllQueryStringWithSearch = <T extends GetAllParamsWithSearch>(
  params: T,
) =>
  buildQueryString({
    ...params,
    pageSize: params?.pageSize || DEFAULT_PAGE_SIZE,
    pattern: params.pattern || undefined,
  });

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${env("api_url")}api/Admin`,
    prepareHeaders,
  }),
  tagTypes,
  endpoints: (builder) => ({
    login: builder.mutation<string, LoginParams>({
      query: (body) => ({
        url: "login",
        method: "POST",
        body,
      }),
      transformResponse: ({ data }: { data: string }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    getAllStories: builder.query<
      PaginatedList<WebStory>,
      GetAllParamsWithSearch
    >({
      query: (params) => ({
        url: `WebStory${buildGetAllQueryStringWithSearch(params)}`,
      }),
      providesTags: ["stories"],
      transformResponse: ({ data }: { data: PaginatedList<WebStory> }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    getAllUsers: builder.query<
      PaginatedList<StoryUser>,
      GetAllParamsWithSearch
    >({
      query: (params) => ({
        url: `User${buildGetAllQueryStringWithSearch(params)}`,
      }),
      providesTags: ["users"],
      transformResponse: ({ data }: { data: PaginatedList<StoryUser> }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    getUserInfo: builder.query<StoryUser, string>({
      query: (id) => ({
        url: `UserInfo/${id}`,
      }),
      providesTags: ["storyUser"],
      transformResponse: ({ data }: { data: StoryUser }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    getOneStory: builder.query<WebStory, string>({
      query: (id) => ({
        url: `Requests${isGuid(id) ? "" : "/Slug"}/${id}`,
      }),
      providesTags: ["story"],
      transformResponse: ({ data }: { data: WebStory }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    regenerateStoryImage: builder.mutation<
      void,
      { webStoryId: string; imageIndex: number }
    >({
      query: (body) => ({
        url: "ManualImageRegeneration",
        method: "POST",
        body,
      }),
      invalidatesTags: ["story"],
      transformErrorResponse: ({ data }) => data,
    }),
    deleteStory: builder.mutation<void, string>({
      query: (id) => ({
        url: `DeletePublishedStory/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["stories", "story", "reportedStory", "reportedStories"],
      transformErrorResponse: ({ data }) => data,
    }),
    getAllAmazonRequests: builder.query<
      PaginatedList<AmazonRequestInterface>,
      AmazonGetAllParams
    >({
      query: (params) => ({
        url: `GetAllAmazonRequests${buildGetAllQueryString<AmazonGetAllParams>(params)}`,
      }),
      transformResponse: ({
        data,
      }: {
        data: PaginatedList<AmazonRequestInterface>;
      }) => data,
      transformErrorResponse: ({ data }) => data,
      providesTags: ["amazonReqList"],
    }),
    getOneAmazonRequest: builder.query<AmazonRequestInterface, string>({
      query: (id: string) => ({
        url: `GetOneAmazonRequest/${id}`,
      }),
      transformResponse: ({ data }: { data: AmazonRequestInterface }) => data,
      transformErrorResponse: ({ data }) => data,
      providesTags: ["amazonReqList"],
    }),
    updateAmazonRequestStatus: builder.mutation<
      any,
      {
        id: string;
        amazonPublishLifecycle: AmazonPublishLifecycle;
        amazonUrl?: string | null;
        amazonRejectReason?: string | null;
      }
    >({
      query: ({
        id,
        amazonPublishLifecycle,
        amazonUrl,
        amazonRejectReason,
      }) => ({
        url: `UpdateAmazonRequestStatus/${id}`,
        method: "PATCH",
        body: { amazonPublishLifecycle, amazonUrl, amazonRejectReason },
      }),
      invalidatesTags: ["amazonReqList", "amazonReq"],
      transformErrorResponse: ({ data }) => data,
    }),
    manualAmazonPDFRegeneration: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `ManualAmazonPDFRegeneration/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["amazonReqList", "amazonReq"],
      transformErrorResponse: ({ data }) => data,
    }),
    getAllStoryItems: builder.query<PaginatedList<StoryItem>, GetAllParams>({
      query: (params) => ({
        url: `GetAllStoryItems${buildGetAllQueryString(params)}`,
      }),
      transformResponse: ({ data }: { data: PaginatedList<StoryItem> }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    getSingleStoryItem: builder.query<
      StoryItem,
      { id: string; storyItemType: string }
    >({
      query: ({ id, storyItemType }) => ({
        url: `${id}/GetSingleStoryItem/${storyItemType}`,
      }),
      transformResponse: ({ data }: { data: StoryItem }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    getAllBookOrders: builder.query<PaginatedList<BookOrder>, RPIGetAllParams>({
      query: (params) => ({
        url: `GetAllBookOrders${buildGetAllQueryString<RPIGetAllParams>(params)}`,
      }),
      transformResponse: ({ data }: { data: PaginatedList<BookOrder> }) => data,
      transformErrorResponse: ({ data }) => data,
      providesTags: ["bookOrders"],
    }),
    getOneBookOrder: builder.query<BookOrder, string>({
      query: (id) => ({
        url: `GetOneBookOrder/${id}`,
      }),
      transformResponse: ({ data }: { data: BookOrder }) => data,
      transformErrorResponse: ({ data }) => data,
      providesTags: ["bookOrder"],
    }),
    getAllDiscountCodes: builder.query<DiscountCodeReturn[], void>({
      query: () => ({
        url: "DiscountCode",
      }),
      providesTags: ["discountCodes"],
      transformResponse: ({ data }: { data: DiscountCodeReturn[] }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    createDiscountCode: builder.mutation<void, DiscountCode>({
      query: (body) => ({
        url: "DiscountCode",
        method: "POST",
        body,
      }),
      invalidatesTags: ["discountCodes"],
      transformResponse: ({ data }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    updateDiscountCode: builder.mutation<
      void,
      ({ isActive: boolean } | { expirationDate: string }) & { id: string }
    >({
      query: (body) => ({
        url: `DiscountCode/${body.id}/${(body as any).expirationDate ? "Expiry" : "Active"}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["discountCodes"],
      transformErrorResponse: ({ data }) => data,
    }),
    getReportedStories: builder.query<
      PaginatedList<ReportedWebStory>,
      GetAllParams
    >({
      query: (params) => ({
        url: `ReportedStories${buildGetAllQueryString(params)}`,
      }),
      providesTags: ["reportedStories"],
      transformResponse: ({
        data,
      }: {
        data: PaginatedList<ReportedWebStory>;
      }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    getReportedComments: builder.query<
      PaginatedList<ReportedComment>,
      GetAllParams
    >({
      query: (params) => ({
        url: `ReportedComments${buildGetAllQueryString(params)}`,
      }),
      providesTags: ["reportedComments"],
      transformResponse: ({ data }: { data: PaginatedList<ReportedComment> }) =>
        data,
      transformErrorResponse: ({ data }) => data,
    }),
    getOneReportedStory: builder.query<ReportedWebStory, string>({
      query: (id) => ({
        url: `ReportedStories/${id}`,
      }),
      providesTags: ["reportedStory"],
      transformResponse: ({ data }: { data: ReportedWebStory }) => data,
      transformErrorResponse: ({ data }) => data,
    }),
    resolveReportedStory: builder.mutation<void, string>({
      query: (id) => ({
        url: `ReportedStories/${id}/resolve`,
        method: "POST",
      }),
      invalidatesTags: ["reportedStories", "reportedStory"],
      transformErrorResponse: ({ data }) => data,
    }),
    deleteReportedComment: builder.mutation<void, string>({
      query: (id) => ({
        url: `DeleteComment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["reportedComments"],
      transformErrorResponse: ({ data }) => data,
    }),
    resolveReportedComment: builder.mutation<void, string>({
      query: (id) => ({
        url: `ReportedComment/${id}/resolve`,
        method: "POST",
      }),
      invalidatesTags: ["reportedComments"],
      transformErrorResponse: ({ data }) => data,
    }),
    deleteUserAccount: builder.mutation<void, string>({
      query: (id) => ({
        url: `User/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "users",
        "stories",
        "story",
        "storyUser",
        "reportedStories",
        "reportedStory",
      ],
      transformErrorResponse: ({ data }) => data,
    }),
    addCredits: builder.mutation<void, { email: string; credits: number }>({
      query: ({ email, credits }) => ({
        url: `AddCredits`,
        method: "POST",
        body: {
          email: email,
          amount: credits,
        },
      }),
      invalidatesTags: ["storyUser"],
      transformErrorResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useGetAllStoriesQuery,
  useGetOneStoryQuery,
  useLazyGetOneStoryQuery,
  useRegenerateStoryImageMutation,
  useDeleteStoryMutation,
  useLoginMutation,
  useGetUserInfoQuery,
  useGetAllAmazonRequestsQuery,
  useGetOneAmazonRequestQuery,
  useUpdateAmazonRequestStatusMutation,
  useManualAmazonPDFRegenerationMutation,
  useGetSingleStoryItemQuery,
  useGetAllStoryItemsQuery,
  useGetAllBookOrdersQuery,
  useGetOneBookOrderQuery,
  useCreateDiscountCodeMutation,
  useGetAllDiscountCodesQuery,
  useUpdateDiscountCodeMutation,
  useGetReportedStoriesQuery,
  useGetOneReportedStoryQuery,
  useResolveReportedStoryMutation,
  useDeleteUserAccountMutation,
  useGetAllUsersQuery,
  useGetReportedCommentsQuery,
  useDeleteReportedCommentMutation,
  useResolveReportedCommentMutation,
  useAddCreditsMutation,
} = baseApi;
