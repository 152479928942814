import { useParams } from "react-router-dom";
import {
  useAddCreditsMutation,
  useDeleteUserAccountMutation,
  useGetUserInfoQuery,
} from "../store/base-api";
import PageError from "../components/errors/page-error";
import LoadingIndicator from "../components/loaders/loading-indicator";
import StoryListItem from "../components/list-items/story-list-item";
import { Fragment, useState } from "react";
import { formatDate, getGenderString, splitOnUppercase } from "../utils";
import { BaseError } from "../models";
import FormError from "../components/errors/form-error";
import PurchaseListItem from "../components/list-items/purchase-list-item";

export default function UserInfo() {
  const { id } = useParams();
  const {
    data,
    isLoading,
    error: fetchError,
  } = useGetUserInfoQuery(id!, { skip: !id });
  const [deleteUser] = useDeleteUserAccountMutation();
  const [addCredits] = useAddCreditsMutation();

  const [creditsToAdd, setCreditsToAdd] = useState(0);

  const [deleting, setDeleting] = useState(false);
  const [addingCredits, setAddingCredits] = useState(false);
  const [error, setError] = useState<BaseError>();

  const getObjectValue = (key: string, value: any) => {
    switch (key) {
      case "created":
        return formatDate(value);
      case "gender":
        return getGenderString(value);
      default:
        return value;
    }
  };

  const deleteUserHandler = async () => {
    if (
      deleting ||
      !id ||
      !window.confirm("Are you sure you want to delete this user?")
    )
      return;
    setDeleting(true);

    try {
      await deleteUser(id).unwrap();
    } catch (e: any) {
      setError(e);
    } finally {
      setDeleting(false);
    }
  };

  const addCreditsHandler = async () => {
    if (
      addingCredits ||
      !id ||
      creditsToAdd < 1 ||
      !data?.email ||
      !window.confirm(
        `Are you sure you want to add $${creditsToAdd} = [${creditsToAdd * 20}] tokens?`,
      )
    )
      return;
    setAddingCredits(true);
    try {
      await addCredits({ email: data.email, credits: creditsToAdd }).unwrap();
    } catch (e: any) {
      setError(e);
    } finally {
      setAddingCredits(false);
      // window.location.reload();
    }
  };

  if (fetchError) return <PageError error={fetchError} />;

  return (
    <div className="flex flex-1 w-full p-4 overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="flex p-8 flex-col flex-1 bg-white border-2 border-neutral-50 rounded-2xl min-h-[calc(100vh-128px)] h-max shadow-xl">
        {isLoading || !data ? (
          <div className="flex-1 grid place-items-center">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <h3 className="text-2xl font-semibold">User Info</h3>
              <div className="flex-1 grid auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                {Object.entries(data)
                  .filter(
                    ([key]) =>
                      key !== "webStories" &&
                      key !== "pdfPurchases" &&
                      key !== "walletBalance",
                  )
                  .map(([key, value]) => (
                    <Fragment key={`user-info-${key}`}>
                      <p className="font-semibold text-lg capitalize">
                        {splitOnUppercase(key)}
                      </p>
                      <div>{getObjectValue(key, value) || "N/A"}</div>
                    </Fragment>
                  ))}
                {/*<Fragment key={`user-info-credits`}>*/}
                {/*  <p className="font-semibold text-lg capitalize">Credits</p>*/}
                {/*  <div>${data.walletBalance / 20 || "N/A"}</div>*/}
                {/*</Fragment>*/}
                {/*<Fragment key={`user-info-add-credits`}>*/}
                {/*  <input*/}
                {/*    type="number"*/}
                {/*    value={creditsToAdd}*/}
                {/*    className={`border-2 border-neutral-200 rounded-md p-2`}*/}
                {/*    onChange={(e) => setCreditsToAdd(+e.target.value)}*/}
                {/*  />*/}
                {/*  <button*/}
                {/*    className="btn-primary"*/}
                {/*    disabled={addingCredits}*/}
                {/*    onClick={addCreditsHandler}*/}
                {/*  >*/}
                {/*    Add Funds*/}
                {/*  </button>*/}
                {/*</Fragment>*/}
              </div>

              {data.email !== "anonymous@storybird.ai" ? (
                <>
                  <FormError error={error} />

                  <button
                    className="btn-error"
                    disabled={deleting}
                    onClick={deleteUserHandler}
                  >
                    Delet{deleting ? "ing" : "e"} User
                  </button>
                </>
              ) : null}
            </div>
            <div>
              <h3 className="text-2xl mb-4 font-semibold">Purchases</h3>
              <div className="flex flex-col flex-1 gap-2">
                {data.pdfPurchases && data.pdfPurchases.length ? (
                  data.pdfPurchases.map((purchase, i) => (
                    <PurchaseListItem
                      key={`pdf-purchase-item-${i + 1}`}
                      {...purchase}
                    />
                  ))
                ) : (
                  <p>This user has no PDF purchases.</p>
                )}
              </div>
            </div>
            <div>
              <h3 className="text-2xl mb-4 font-semibold">User Stories</h3>
              <div className="flex flex-col flex-1 gap-2">
                {data.webStories.length ? (
                  data.webStories.map((story, i) => (
                    <StoryListItem
                      key={`story-item-${i + 1}`}
                      {...{ ...story, user: data }}
                    />
                  ))
                ) : (
                  <p>This user has no web stories.</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
