import { Link } from "react-router-dom";
import { AmazonRequestInterface } from "../../models";
import { formatDate } from "../../utils";

export default function AmazonRequestListItem({
  webStoryId,
  storyTitle,
  authorFirstName,
  authorLastName,
  created,
}: AmazonRequestInterface) {
  return (
    <Link
      to={`/amazon-requests/${webStoryId}`}
      className="flex flex-col gap-2 p-4 rounded-2xl border-2 border-neutral-50 bg-white shadow-md cursor-pointer hover:bg-neutral-50"
    >
      <p className="text-2xl font-semibold">{storyTitle}</p>
      <p>
        By {authorFirstName} {authorLastName}
      </p>
      <p>Created on {formatDate(created)}</p>
    </Link>
  );
}
