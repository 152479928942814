import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../components/ui/logo";
import FormError from "../components/errors/form-error";
import { LoginParams, useLoginMutation } from "../store/base-api";
import { useAppDispatch } from "../store/store";
import { enableAuth } from "../store/auth-store";
import { decodeToken } from "../utils";

export default function Auth() {
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState<LoginParams>({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const loginHandler = async (event: FormEvent) => {
    event.preventDefault();
    if (loading) return;
    setLoading(true);

    try {
      const token = await login(formData).unwrap();
      dispatch(enableAuth(token));
      const isAdmin = decodeToken(token).role === "Admin";
      navigate(isAdmin ? "stories" : "amazon-requests", { replace: true });
    } catch (e: any) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="flex flex-col gap-8 flex-1 items-center justify-center">
      <div className="flex flex-col gap-4 text-center">
        <Logo className="h-64 w-64 p-8" logoClassName="w-28 h-28" />
        <h1 className="font-bold text-3xl">Story.com Admin</h1>
      </div>

      <form
        onSubmit={loginHandler}
        className="flex flex-col gap-4 w-full max-w-[300px]"
      >
        <div className="flex flex-col">
          <label htmlFor="email" className="ml-2 font-semibold text-sm">
            Email
          </label>
          <input
            className="border-2 border-brand-outline px-4 py-2 rounded-2xl"
            onChange={handleChange}
            name="email"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="password" className="ml-2 font-semibold text-sm">
            Password
          </label>
          <input
            className="border-2 border-brand-outline px-4 py-2 rounded-2xl"
            onChange={handleChange}
            name="password"
            type="password"
            placeholder="Password"
          />
        </div>
        <button className="btn-primary mt-4 w-full" disabled={loading}>
          {loading ? "Loading" : "Login"}
        </button>

        <div className="mx-auto">
          <FormError error={error} />
        </div>
      </form>
    </div>
  );
}
