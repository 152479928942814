import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageError from "../components/errors/page-error";
import LoadingIndicator from "../components/loaders/loading-indicator";
import {
  useGetOneAmazonRequestQuery,
  useUpdateAmazonRequestStatusMutation,
  useManualAmazonPDFRegenerationMutation,
} from "../store/base-api";

import { ReactComponent as RefreshIcon } from "../assets/icons/rotate-cw.svg";

import { AmazonPublishLifecycle } from "../models";
import FormError from "../components/errors/form-error";
import Downloadable from "../components/inputs/downloadable";
import {
  env,
  formatDate,
  getKeyOfObjFromValue,
  splitOnUppercase,
} from "../utils";

const orderedKeys = [
  "webStoryId",
  "language",
  "storyTitle",
  "subtitle",
  "authorFirstName",
  "authorLastName",
  "storySummary",
  "ageGroupMin",
  "ageGroupMax",
  "amazonMarketplace",
  "category1",
  "category2",
  "category3",
  "seoKeywords",
  "gutsPDFUrl",
  "coverPDFUrl",
  "amazonPublishLifecycle",
  "publicationDate",
  "amazonUrl",
  "amazonPublishDate",
  "created",
];

export default function AmazonRequest() {
  const { id } = useParams();
  const [updateStatus] = useUpdateAmazonRequestStatusMutation();
  const {
    data,
    isLoading,
    isFetching,
    error: fetchError,
  } = useGetOneAmazonRequestQuery(id!, {
    skip: !id,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [submitBtnTxt, setSubmitBtnTxt] = useState("");
  const [storyData, setStoryData] = useState<[string, any][]>([]);
  const [amazonUrl, setAmazonUrl] = useState("");
  const [rejectReason, setRejectReason] = useState("");

  useEffect(() => {
    setLoading(isFetching || isLoading);
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (!data) return;
    const { amazonPublishLifecycle: alc } = data;
    if (
      alc === AmazonPublishLifecycle.Published ||
      alc === AmazonPublishLifecycle.Rejected ||
      alc === AmazonPublishLifecycle.SelfPublished
    )
      return setSubmitBtnTxt("");
    setSubmitBtnTxt(
      "Move to " +
        (alc === AmazonPublishLifecycle.Requested ? "processing" : "published"),
    );
  }, [data]);

  const incrementPublishLifecycle = async (reject = false) => {
    if (
      !id ||
      !data ||
      loading ||
      data.amazonPublishLifecycle === AmazonPublishLifecycle.Published
    )
      return;

    setLoading(true);

    const requestBody: {
      id: string;
      amazonPublishLifecycle: number;
      amazonUrl: string | null;
      amazonRejectReason: string | null;
    } = {
      id,
      amazonPublishLifecycle: reject
        ? AmazonPublishLifecycle.Rejected
        : data.amazonPublishLifecycle + 1,
      amazonUrl:
        data.amazonPublishLifecycle === AmazonPublishLifecycle.Processing &&
        amazonUrl !== ""
          ? amazonUrl
          : null,
      amazonRejectReason: reject
        ? rejectReason === ""
          ? null
          : rejectReason
        : null,
    };

    try {
      await updateStatus(requestBody).unwrap();
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Given Amazon Marketplace Enum value, return the string value

  const marketplaces = {
    0: "US",
    1: "CA",
    2: "MX",
    3: "BR",
    4: "DE",
    5: "FR",
    6: "IT",
    7: "ES",
    8: "AU",
    9: "IN",
    10: "JP",
    11: "NL",
    12: "UK",
  };

  const [regeneratePDF, { isLoading: isRegenerating }] =
    useManualAmazonPDFRegenerationMutation();

  const handlePDFRegeneration = async () => {
    if (!data?.webStoryId) return;

    const userConfirmed = window.confirm(
      "Are you sure you want to regenerate the PDF? " +
        "Please do not spam this action as it triggers a regeneration in the backend. " +
        "Refresh the page in 1-2 minutes to see the updated PDF.",
    );

    if (!userConfirmed) return;

    try {
      await regeneratePDF({ id: data.webStoryId }).unwrap();
    } catch (error) {
      console.error("Failed to regenerate PDF:", error);
    }
  };

  const valueRenderers = {
    webStoryId: (value: any) => (
      <a
        href={
          env("app_url") +
          "story/" +
          data?.topLevelCategory +
          "/" +
          data?.slug +
          "/publish-book/download"
        }
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline hover:text-blue-700"
      >
        {env("app_url") +
          "story/" +
          data?.topLevelCategory +
          "/" +
          data?.slug +
          "/publish-book/download"}
      </a>
    ),
    storyTitle: () => data?.returnBookMetaDataDTO?.title || data?.storyTitle,
    coverPDFUrl: (value: any) => <Downloadable url={value} />,
    gutsPDFUrl: (value: any) => (
      <div className="flex items-center">
        <Downloadable url={value} />
        <button
          onClick={handlePDFRegeneration}
          disabled={isRegenerating}
          className="ml-2"
          title="Regenerate PDF"
        >
          <RefreshIcon className="w-6 h-6" />
        </button>
      </div>
    ),
    storySummary: () =>
      data?.returnBookMetaDataDTO?.summary || data?.storySummary,
    publicationDate: formatDate,
    amazonUrl: (value: any) => {
      if (data?.amazonPublishLifecycle === AmazonPublishLifecycle.Processing) {
        return (
          <input
            type="text"
            value={value}
            onChange={(e) => setAmazonUrl(e.target.value)}
            className="border-2 p-2 w-full"
            placeholder="Amazon URL"
          />
        );
      } else if (
        data?.amazonPublishLifecycle === AmazonPublishLifecycle.Published
      ) {
        return (
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline hover:text-blue-700"
          >
            {value}
          </a>
        );
      }
      return value;
    },
    amazonPublishDate: formatDate,
    created: formatDate,
    amazonPublishLifecycle: (value: any) =>
      splitOnUppercase(getKeyOfObjFromValue(AmazonPublishLifecycle, value)),
    amazonMarketplace: (value: any) => marketplaces[value],
  };

  const getObjectValue = (key: string, value: any) => {
    return valueRenderers[key] ? valueRenderers[key](value) : value;
  };

  useEffect(() => {
    if (!data) return;
    const newStoryData: [string, any][] = [];
    const seenKeys = new Set<string>();

    const flattenCategories = (category: any, path = "") => {
      let results: string[] = [];
      if (Array.isArray(category)) {
        category.forEach((item) => {
          results = results.concat(flattenCategories(item, path));
        });
      } else {
        for (const [key, value] of Object.entries(category)) {
          const newPath = path ? path + " > " + key : key;
          if (Object.keys(value as any).length) {
            results = results.concat(flattenCategories(value, newPath));
          } else {
            results.push(newPath);
          }
        }
      }
      return results;
    };

    const flatData = Object.entries(data).reduce((acc, [key, value]) => {
      if (key === "returnBookMetaDataDTO") {
        for (const [subKey, subValue] of Object.entries(value)) {
          if (subKey === "categories") {
            const flattenedCategories = flattenCategories(subValue);
            flattenedCategories.forEach((category, index) => {
              acc[`category${index + 1}`] = category;
            });
          } else {
            acc[subKey] = subValue;
          }
        }
      } else {
        acc[key] = value;
      }
      return acc;
    }, {} as any);

    for (let i = 0; i < orderedKeys.length; i++) {
      const key = orderedKeys[i];
      const value = flatData[key];

      if (key === "returnBookMetaDataDTO") {
        for (const [subKey, subValue] of Object.entries(value)) {
          console.log("subKey", subKey, subValue);
          if (subKey === "categories") {
            const flattenedCategories = flattenCategories(subValue);
            flattenedCategories.forEach((category, index) => {
              newStoryData.push([`category${index + 1}`, category]);
            });
          } else {
            newStoryData.push([subKey, subValue]);
            seenKeys.add(subKey);
          }
        }
      } else {
        newStoryData.push([key, value]);
        seenKeys.add(key);
      }
    }

    // Append any remaining fields not in orderedKeys
    // Commented out because not sure we want this (there are some duplicative fields)
    // for (const [key, value] of Object.entries(data)) {
    //     if (!seenKeys.has(key) && key !== "returnBookMetaDataDTO") {
    //         newStoryData.push([key, value]);
    //     }
    // }

    setStoryData([...newStoryData]);
  }, [data]);

  if (fetchError) return <PageError error={fetchError} />;

  return (
    <div className="flex flex-1 w-full p-4 max-h-[calc(100vh-96px)] overflow-y-auto">
      <div className="flex p-8 flex-col flex-1 bg-white border-2 border-neutral-50 rounded-2xl min-h-[calc(100vh-128px)] h-max shadow-md">
        {loading || !data ? (
          <div className="flex-1 grid place-items-center">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="grid w-full max-w-5xl gap-y-2 gap-x-8 grid-cols-[max-content,minmax(0,_1fr)] auto-rows-min">
              {storyData.map(([key, value]) => (
                <Fragment key={key}>
                  <p className="font-semibold capitalize text-lg">
                    {splitOnUppercase(key)}
                  </p>
                  <div>{getObjectValue(key, value) || "N/A"}</div>
                </Fragment>
              ))}
              {(data?.amazonPublishLifecycle ===
                AmazonPublishLifecycle.Processing ||
                data?.amazonPublishLifecycle ==
                  AmazonPublishLifecycle.Requested) && (
                <Fragment>
                  <p className="font-semibold capitalize text-lg">
                    Reject Reason
                  </p>
                  <div>
                    <textarea
                      className="mt-2 w-full p-2 border rounded"
                      placeholder="Enter reject reason..."
                      value={rejectReason}
                      onChange={(e) => setRejectReason(e.target.value)}
                      rows={4}
                    />
                  </div>
                </Fragment>
              )}
            </div>

            {submitBtnTxt ? (
              <div className="mt-4 gap-8 flex-wrap flex items-center">
                <button
                  className="btn-primary"
                  onClick={() => incrementPublishLifecycle()}
                >
                  {submitBtnTxt}
                </button>
                {(data?.amazonPublishLifecycle ===
                  AmazonPublishLifecycle.Processing ||
                  data?.amazonPublishLifecycle ==
                    AmazonPublishLifecycle.Requested) && (
                  <button
                    className="btn-reject bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded border-0 mr-2 transition duration-150 ease-in-out"
                    onClick={() => incrementPublishLifecycle(true)}
                  >
                    Reject
                  </button>
                )}
                <FormError error={error} />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
