import { useNavigate } from "react-router-dom";
import {
  CreditSpendType,
  CreditSpendTypeToDescription,
  PdfPurchase,
} from "../../models";

export default function PurchaseListItem({
  webStoryId,
  storyTitle,
  topLevelCategory,
  slug,
  creditSpendType,
}: PdfPurchase) {
  const navigate = useNavigate();

  const goToPurchaseHandler = () => {
    if (
      creditSpendType === CreditSpendType.AmazonPublishing ||
      creditSpendType === CreditSpendType.AmazonPublishingPDFOnly
    ) {
      navigate(`/amazon-requests/${webStoryId}`);
    } else {
      navigate(`/story-pdfs/${webStoryId}/${creditSpendType}`);
    }
  };

  return (
    <div
      onClick={goToPurchaseHandler}
      className="flex justify-between items-center gap-2 p-4 border-2 border-neutral-50 rounded-2xl bg-white shadow-md cursor-pointer hover:bg-neutral-50"
    >
      <div>
        <p className="text-2xl font-semibold">{storyTitle}</p>
        <p>{CreditSpendTypeToDescription[creditSpendType]}</p>
      </div>
    </div>
  );
}
