import { useParams } from "react-router-dom";
import { useGetOneBookOrderQuery } from "../store/base-api";
import { Fragment } from "react";
import PageError from "../components/errors/page-error";
import LoadingIndicator from "../components/loaders/loading-indicator";
import { BookOrderLifecycle, ShippingClassification } from "../models";
import Downloadable from "../components/inputs/downloadable";
import { formatDate, getKeyOfObjFromValue, splitOnUppercase } from "../utils";

export default function RPIRequest() {
  const { id } = useParams();
  const {
    data,
    isLoading,
    error: fetchError,
  } = useGetOneBookOrderQuery(id!, {
    skip: !id,
  });

  const getObjectValue = (key: string, value: any) => {
    switch (key) {
      case "coverPDFUrl":
      case "gutsPDFUrl":
        return <Downloadable url={value} />;
      case "created":
        return formatDate(value);
      case "shippingClassification":
        return splitOnUppercase(
          getKeyOfObjFromValue(ShippingClassification, value),
        );
      case "bookOrderLifecycle":
        return splitOnUppercase(
          getKeyOfObjFromValue(BookOrderLifecycle, value),
        );
      case "orderPrice":
      case "shippingPrice":
      case "totalPrice":
        return `$${value || 0}`;
      default:
        return value;
    }
  };

  if (fetchError) return <PageError error={fetchError} />;

  return (
    <div className="flex flex-1 w-full p-4 max-h-[calc(100vh-96px)] overflow-y-auto">
      <div className="flex p-8 flex-col flex-1 border-2 border-neutral-50 bg-white rounded-2xl min-h-[calc(100vh-128px)] h-max shadow-xl">
        {isLoading || !data ? (
          <div className="flex-1 grid place-items-center">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex-1 grid auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
            {Object.entries(data).map(([key, value]) =>
              key !== "coverImage" ? (
                <Fragment key={key}>
                  <p className="font-semibold text-lg capitalize">
                    {splitOnUppercase(key)}
                  </p>
                  <div>{getObjectValue(key, value) || "N/A"}</div>
                </Fragment>
              ) : null,
            )}
          </div>
        )}
      </div>
    </div>
  );
}
