import dayjs from "dayjs";
import { BaseError, DiscountCode, DiscountCodeType } from "../models";
import Toggle from "../components/inputs/toggle";
import { ChangeEvent, useEffect, useState } from "react";
import { useCreateDiscountCodeMutation } from "../store/base-api";
import { useNavigate } from "react-router-dom";

const EMPTY_DISCOUNT_CODE: DiscountCode = {
  code: "",
  expirationDate: "",
  discountCodeType: DiscountCodeType.None,
  discountPercentage: 0,
  isActive: true,
};

export default function CreateDiscountCode() {
  const navigate = useNavigate();
  const [createCode] = useCreateDiscountCodeMutation();

  const [formData, setFormData] = useState(EMPTY_DISCOUNT_CODE);
  const [createDisabled, setCreateDisabled] = useState(true);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState<BaseError>();

  useEffect(() => {
    const validDate = formData.expirationDate !== "Invalid Date";
    setCreateDisabled(
      creating || !validDate || !formData.code || !formData.discountPercentage,
    );
  }, [creating, formData]);

  const handleChange = ({
    target: { id, name, value },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let v: string | number = value;
    if ([name, id].includes("expirationDate"))
      v = dayjs(v).format("YYYY-MM-DD");
    if (value && !isNaN(Number(value))) v = Number(value);
    setFormData({ ...formData, [name || id]: v });
  };

  const createNewDiscountCode = async () => {
    if (createDisabled) return;
    setCreating(true);

    try {
      await createCode(formData).unwrap();
      navigate("/discount-codes", { replace: true });
    } catch (e: any) {
      setError(e);
    } finally {
      setCreating(false);
    }
  };

  return (
    <div className="flex flex-1 w-full p-4 overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="flex p-8 gap-5 flex-col flex-1 h-max min-h-[calc(100vh-128px)] border-2 border-neutral-50 bg-white rounded-2xl shadow-xl">
        <div className="flex flex-col gap-2 max-w-[250px]">
          <div className="flex flex-col gap-0.5">
            <label htmlFor="code" className="ml-2 text-sm font-semibold">
              Code
            </label>
            <input
              className="border-2 py-1 px-2 border-secondary-900 rounded-xl"
              value={formData.code}
              onChange={handleChange}
              id="code"
            />
          </div>

          <div className="flex flex-col gap-0.5">
            <label
              htmlFor="discountPercentage"
              className="ml-2 text-sm font-semibold"
            >
              Percentage Discount
            </label>
            <input
              className="border-2 py-1 px-2 border-secondary-900 rounded-xl"
              value={formData.discountPercentage}
              maxLength={3}
              onChange={handleChange}
              id="discountPercentage"
            />
          </div>

          <div className="flex flex-col gap-0.5">
            <label htmlFor="type" className="ml-2 text-sm font-semibold">
              Type
            </label>
            <div className="border-2 py-1 px-2 border-secondary-900 rounded-xl focus-within:focused cursor-pointer">
              <select
                defaultValue=""
                name="discountCodeType"
                className="w-full focus:outline-none"
                onChange={handleChange}
              >
                <option value={DiscountCodeType.None}>None</option>
                <option value={DiscountCodeType.BookOrder}>Book Order</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col gap-0.5">
            <label
              htmlFor="expirationDate"
              className="ml-2 text-sm font-semibold"
            >
              Expiry Date
            </label>
            <input
              className="border-2 py-1 px-2 border-secondary-900 rounded-xl"
              type="date"
              id="expirationDate"
              value={formData.expirationDate}
              onChange={handleChange}
              min={dayjs().format("YYYY-MM-DD")}
            />
          </div>

          <div className="flex items-center justify-between py-2 gap-4">
            <label className="ml-2 font-semibold">Is Active</label>
            <Toggle
              isToggled={formData.isActive}
              setIsToggled={(isActive) =>
                setFormData({ ...formData, isActive })
              }
            />
          </div>
        </div>

        <button
          className="btn-primary"
          onClick={createNewDiscountCode}
          disabled={createDisabled}
        >
          Creat{creating ? "ing" : "e"} New
        </button>
      </div>
    </div>
  );
}
