import { Link } from "react-router-dom";
import { formatDate } from "../../utils";
import { ReportedComment } from "../../models";

export default function ReportedCommentListItem(
  reportedComment: ReportedComment,
) {
  return (
    <Link
      state={{ reportedComment: reportedComment }}
      to={`/reported-comments/${reportedComment.comment.id}`}
      className="flex flex-col gap-2 p-4 rounded-2xl border-2 border-neutral-50 bg-white shadow-md cursor-pointer hover:bg-neutral-50"
    >
      <div className="flex items-center justify-between gap-x-8 gap-y-2 flex-wrap-reverse">
        <p className="text-2xl font-semibold">
          {reportedComment.comment.commentText}
        </p>
      </div>
      <p>Reported by: {reportedComment.user.name}</p>
      <p>Created on {formatDate(reportedComment.comment.created)}</p>
    </Link>
  );
}
