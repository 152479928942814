export default function Toggle({
  isToggled,
  setIsToggled,
}: {
  isToggled: boolean;
  setIsToggled(val: boolean): void;
}) {
  return (
    <button
      onClick={() => setIsToggled(!isToggled)}
      aria-checked={isToggled}
      className="toggle"
    >
      <span />
    </button>
  );
}
