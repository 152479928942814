import { Link } from "react-router-dom";
import { WebStory } from "../../models";
import { formatDate } from "../../utils";
import { ReactComponent as DeleteIcon } from "../../assets/icons/trash.svg";
import { MouseEvent, useState } from "react";
import { useDeleteStoryMutation } from "../../store/base-api";

export default function StoryListItem({
  id,
  storyTitle,
  created,
  user,
  isPublic,
}: WebStory) {
  const [deleteStory] = useDeleteStoryMutation();
  const [deletingStory, setDeletingStory] = useState(false);

  const deleteStoryHandler = async (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (deletingStory || !isPublic) return;

    if (window.confirm("Are you sure you want to delete this story?")) {
      setDeletingStory(true);
      try {
        await deleteStory(id).unwrap();
      } catch (e: any) {
        window.alert(e);
      } finally {
        setDeletingStory(false);
      }
    }
  };

  return (
    <Link
      to={`/stories/${id}`}
      style={
        deletingStory ? { opacity: 0.5, pointerEvents: "none" } : undefined
      }
      className="flex justify-between items-center gap-2 p-4 border-2 border-neutral-50 rounded-2xl bg-white shadow-md cursor-pointer hover:bg-neutral-50 no-underline text-inherit"
    >
      <div>
        <p className="text-2xl font-semibold">{storyTitle}</p>
        <p>by {user.name}</p>
        <p>created on {formatDate(created)}</p>
      </div>

      <button
        disabled={deletingStory}
        onClick={deleteStoryHandler}
        className="p-2 svg-btn after:bg-error-500/50"
      >
        <DeleteIcon height={20} width={20} />
      </button>
    </Link>
  );
}
