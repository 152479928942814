import { Link } from "react-router-dom";
import { ReportedWebStory } from "../../models";
import { formatDate } from "../../utils";

export default function ReportedStoryListItem({
  id,
  storyTitle,
  created,
  storyReports,
  user,
}: ReportedWebStory) {
  return (
    <Link
      to={`/reports/${id}`}
      className="flex flex-col gap-2 p-4 rounded-2xl border-2 border-neutral-50 bg-white shadow-md cursor-pointer hover:bg-neutral-50"
    >
      <div className="flex items-center justify-between gap-x-8 gap-y-2 flex-wrap-reverse">
        <p className="text-2xl font-semibold">{storyTitle}</p>
        <p>
          Total reports:{" "}
          <span className="font-semibold">{storyReports?.length}</span>
        </p>
      </div>
      <p>By {user.name}</p>
      <p>Created on {formatDate(created)}</p>
    </Link>
  );
}
