import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteReportedCommentMutation,
  useResolveReportedCommentMutation,
} from "../store/base-api";
import { Fragment, useState } from "react";
import { BaseError, CommentReportReason } from "../models";
import LoadingIndicator from "../components/loaders/loading-indicator";
import { formatDate, splitOnUppercase } from "../utils";
import FormError from "../components/errors/form-error";

export default function ReportedComment() {
  const { state } = useLocation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [resolveReport] = useResolveReportedCommentMutation();
  const [deleteComment] = useDeleteReportedCommentMutation();

  const [resolvingReports, setResolvingReports] = useState(false);
  const [deletingComment, setDeletingComment] = useState(false);

  const [error, setError] = useState<BaseError>();

  const resolveReportsHandler = async () => {
    if (resolvingReports || !id) return;

    setResolvingReports(true);

    try {
      await resolveReport(id).unwrap();
      navigate("/reports?currentTab=comments", { replace: true });
    } catch (e: any) {
      setError(e);
    } finally {
      setResolvingReports(false);
    }
  };

  const deleteCommentHandler = async () => {
    if (
      deletingComment ||
      !id ||
      !window.confirm("Are you sure you want to delete this comment?")
    )
      return;

    setDeletingComment(true);
    try {
      await deleteComment(id).unwrap();
      navigate("/reports?currentTab=comments", { replace: true });
    } catch (e: any) {
      setError(e);
    } finally {
      setDeletingComment(false);
    }
  };

  const getObjectValue = (key: string, value: any) => {
    switch (key) {
      case "created":
        return formatDate(value);

      default:
        return value;
    }
  };

  const getReportReasonString = (reason: CommentReportReason) => {
    switch (reason) {
      case CommentReportReason.InappropriateText:
        return "Comment contain inappropriate language.";

      case CommentReportReason.HateSpeech:
        return "Comment contains hateful themes towards real people.";

      case CommentReportReason.Irrelevant:
        return "Comment is irrelevant.";

      default:
        return "Other reason";
    }
  };

  return (
    <div className="flex flex-1 w-full p-4 overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="flex p-8 flex-col flex-1 h-max min-h-[calc(100vh-128px)] border-2 border-neutral-50 bg-white rounded-2xl shadow-xl">
        {!state ? ( //TODO: fix loading state
          <div className="flex-1 grid place-items-center">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex-1 flex flex-col gap-8 max-w-5xl">
            <div className="flex flex-col gap-4">
              <h3 className="text-2xl font-semibold">Comment Info</h3>
              <div className="grid auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                {Object.entries(state.reportedComment.comment).map(
                  ([key, value]) => (
                    <Fragment key={key}>
                      <p className="font-semibold text-lg capitalize">
                        {splitOnUppercase(key)}
                      </p>
                      <div>{getObjectValue(key, value) || "N/A"}</div>
                    </Fragment>
                  ),
                )}
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <h3 className="text-2xl font-semibold">Created By</h3>
              <div className="grid auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                {Object.entries(state.reportedComment.user).map(
                  ([key, value]) => (
                    <Fragment key={`user-info-${key}`}>
                      <p className="font-semibold text-lg capitalize">
                        {splitOnUppercase(key)}
                      </p>
                      <div>{getObjectValue(key, value) || "N/A"}</div>
                    </Fragment>
                  ),
                )}
              </div>
              <Link
                to={`/users/${state.reportedComment.user.id}`}
                className="btn-primary"
              >
                View User
              </Link>
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between gap-8">
                <h3 className="text-2xl font-semibold">Comment Report</h3>
                <div className="flex items-center gap-4">
                  <button
                    disabled={resolvingReports || deletingComment}
                    onClick={resolveReportsHandler}
                    className="btn-primary"
                  >
                    Resolv{resolvingReports ? "ing" : "e"} Report
                  </button>
                  <button
                    disabled={deletingComment || resolvingReports}
                    onClick={deleteCommentHandler}
                    className="btn-error"
                  >
                    Delet{deletingComment ? "ing" : "e"} Comment
                  </button>
                </div>
              </div>

              <FormError error={error} />

              <div className="flex flex-col flex-1 gap-2">
                {state.reportedComment ? (
                  <div
                    key={`story-report-${state.reportedComment.user.id}`}
                    className="flex flex-col gap-2 p-4 border-2 border-neutral-50 rounded-2xl bg-white shadow-md"
                  >
                    <div className="grid w-full auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                      <h4 className="text-xl font-semibold col-span-full pb-0.5 mb-1 border-b-2 border-neutral-200">
                        Report
                      </h4>
                      <p className="font-semibold capitalize">report reason</p>
                      <p>
                        {getReportReasonString(
                          state.reportedComment.commentReportReason,
                        )}
                      </p>
                      <p className="font-semibold capitalize">other reason</p>
                      <p>{state.reportedComment.otherReason}</p>
                      <h4 className="text-xl font-semibold col-span-full pb-0.5 mb-1 border-b-2 border-neutral-200">
                        Reported by
                      </h4>
                      {Object.entries(state.reportedComment.user).map(
                        ([key, value]) => (
                          <Fragment key={`user-info-${key}`}>
                            <p className="font-semibold capitalize">
                              {splitOnUppercase(key)}
                            </p>
                            <div>{getObjectValue(key, value) || "N/A"}</div>
                          </Fragment>
                        ),
                      )}
                    </div>
                    <Link
                      to={`/users/${state.reportedComment.user.id}`}
                      className="mt-2 btn-primary"
                    >
                      View User
                    </Link>
                  </div>
                ) : (
                  <p>There are no reports for this comment.</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
