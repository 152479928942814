import { Link } from "react-router-dom";
import { formatDate } from "../../utils";
import { CreditSpendType, CreditSpendTypeToDescription } from "../../models";

interface StoryItemInterface {
  webStoryId: string;
  storyTitle: string;
  creditSpendType: CreditSpendType;
  created: string;
}

export default function StoryItemListItem({
  webStoryId,
  storyTitle,
  creditSpendType,
  created,
}: StoryItemInterface) {
  return (
    <Link
      to={`/story-items/${webStoryId}/${creditSpendType}`}
      className="flex flex-col gap-2 p-4 rounded-2xl border-2 border-neutral-50 bg-white shadow-md cursor-pointer hover:bg-neutral-50"
    >
      <p className="text-2xl font-semibold">{storyTitle}</p>
      <p>{CreditSpendTypeToDescription[creditSpendType]}</p>
      <p>Created on {formatDate(created)}</p>
    </Link>
  );
}
