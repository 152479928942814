import { Link } from "react-router-dom";
import { DiscountCodeReturn } from "../../models";
import { formatDate, getDiscountCodeTypeString } from "../../utils";

export default function DiscountCodeListItem(dc: DiscountCodeReturn) {
  const {
    id,
    code,
    isActive,
    expirationDate,
    discountCodeType,
    discountPercentage,
  } = dc;
  return (
    <Link
      to={`/discount-codes/${id}`}
      state={dc}
      className="flex gap-4 p-4 rounded-2xl border-2 border-neutral-50 bg-white shadow-md cursor-pointer hover:bg-neutral-50"
    >
      <div className="flex flex-col flex-1 gap-2">
        <p className="text-xl font-semibold">{code}</p>
        <p>
          {getDiscountCodeTypeString(discountCodeType)} - %{discountPercentage}{" "}
          off
        </p>
        <p>Valid until {formatDate(expirationDate)}</p>
      </div>
      <div className={`discount-tag ${isActive ? "active" : ""}`}>
        {isActive ? "" : "in"}active
      </div>
    </Link>
  );
}
